import paths from "~/constants/paths";
export default defineNuxtRouteMiddleware((to) => {
  const user = useCookie("hs-token");

  if (to.path !== paths.login && to.path !== paths.register && !user.value) {
    return navigateTo("/login");
  }

  if ((to.path === paths.login || to.path === paths.register) && user.value) {
    return navigateTo("/");
  }
});
